:root {
  /************************** color pallet */
  --first-color: #1A1A1A;
  --second-color: #D4AF37;
  /* --second-color: #FFD700; */
  --third-color: #333333;
  --fourth-color: #707070;
  --content-color: #EDEDED;
  --border-color: #444444;
  --white: #fff;
  --section-odd-color: #fff;
  --section-even-color: #e7e7e7;
}


/* Scroll bar design */


::-webkit-scrollbar {
  width: 10px;
  background-color: var(--first-standard-color);
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--first-standard-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(248, 201, 123);
  filter: opacity(70%);
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: 0.3s;
}